
import SpecialSnoring from '../components/SpecialSnoring';
import { Helmet } from 'react-helmet';

 const SpecialSnoringPage = () =>{
    return(
     <>
      <Helmet>
            <title>Leczenie chrapania i bezdechu sennego Rzeszów | Mini Clinic</title>
            <meta name='description' content='Posiadamy wieloletnie doświadczenie w leczeniu chrapania i bezdechu sennego. Konsultacje, diagnostyka i zabiegi operacyjne. Serdecznie zapraszamy!' />
        </Helmet>
        <SpecialSnoring />
       </>
    );
  }
  export default SpecialSnoringPage;
 